<template>
  <div class="details-cont">
    <div class="details-name">{{info.contractName}}</div>
    <div class="box">
      <div class="box-name">合同信息：</div>
      <div class="box-cont">
        <table class="d-table"> 
          <tr>
            <td class="d-th" colspan="1">合同号</td>
            <td colspan="3">{{info.contractNo}}</td>
          </tr>
          <!-- <tr>
            <td class="d-th">生效日期</td>
            <td class="d-td">{{info.startTime}}</td>
            <td class="d-th">失效日期</td>
            <td class="d-td">{{info.endTime}}</td>
          </tr> -->
          <tr>
            <td class="d-th">生效日期</td>
            <td colspan="3">{{info.startTime}}</td>
          </tr>
          <tr>
            <td class="d-th">失效日期</td>
            <td colspan="3">{{info.endTime}}</td>
          </tr>
          <tr>
            <td class="d-th">IMEI</td>
            <td colspan="3">{{info.imei}}</td>
          </tr>
          <tr>
            <td class="d-th">合同状态</td>
            <td colspan="3" :class="{'on': info.state == '2'}">{{info.stateName}}</td>
          </tr>
          <tr v-show="applyInfo.stateName">
            <td class="d-th">申请状态</td>
            <td colspan="3">
              {{applyInfo.stateName}}
              <div v-if="applyInfo.approvalInfos && applyInfo.approvalInfos.length > 0" class="red">
                {{applyInfo.approvalInfos.join(',')}}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="box">
      <div class="box-name">保障内容：</div>
      <div class="box-cont">

      </div>
    </div>

    <div class="box">
      <div class="box-name">客户信息：</div>
      <div class="box-cont"></div>
    </div>

    <div class="box">
      <div class="box-name">缴费信息：</div>
      <div class="box-cont"></div>
    </div> -->

    <div class="box">
      <div class="box-name">其他信息：</div>
      <div class="box-cont">
        <div class="nodata">无</div>
      </div>
    </div>

    <div class="box">
      <div class="box-name">服务记录：</div>
      <div class="box-cont">
        <table class="d-table"> 
          <tr v-for="(item,index) in applyList" :key="index">
            <td class="d-th" colspan="3">{{item.applyDate}}	</td>
            <td>{{item.stateName}}
              <div v-if="item.approvalInfos && item.approvalInfos.length > 0" class="red">
                {{item.approvalInfos.join(',')}}
              </div>
            </td>
          </tr>
        </table>
        <div class="nodata" v-show="applyList.length == 0">无</div>
      </div>
    </div>

    <div v-show="info.state == '2'">
      <div class="form-sub" v-show="! applyInfo.applyId" @click="goAsk('add')">申请服务</div>
      <div class="form-sub" v-show="applyInfo.state && applyInfo.state != 3" @click="goAsk('view')">查看申请</div>
      <div class="form-sub" v-show="applyInfo.state == 3" @click="goAsk('edit')">修改申请</div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      id: this.$route.params.id,
      contractNo: this.$route.params.no,
      info: {}, // 合同详情
      applyList: [],
      applyInfo: {}, // 当前申请单详情
    }
  },
  mounted () {
    this.getInfo()
    this.getApply()
  },
  methods: {
    /**
     * 前去申请
     */
    goAsk (type) {
      if(type == 'add'){
        this.$router.push({
          path: '/ask/form',
          query: {
            id: this.id,
            contractNo: this.contractNo,
            type: type
          }
        })
      }else{
        this.$router.push({
          path: '/ask/auth',
          query: {
            id: this.id,
            contractNo: this.contractNo,
            type: type,
            applyId: this.applyInfo.applyId,
          }
        })
      }
    },
    /**
     * 获取申请单
     */
    getApply () {
      const vm = this;
      let postData = {
        contractItemId: this.id
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/apply/queryApplyByContractNo", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.applyList = response.data.body.contractApplyList ? response.data.body.contractApplyList : [];
            vm.applyInfo = vm.applyList[vm.applyList.length-1] ? vm.applyList[vm.applyList.length-1] : {};
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 获取合同详情
     */
    getInfo () {
      const vm = this;
      let postData = {
        contractItemId: this.id
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/contractService/queryServiceDetail", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.info = response.data.body;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 返回页面
     */
    goBack () {
      this.$router.back(-1)
    },
  },
  filters: {
    // toDate (str) {
    //   if(str){
    //     return str.subS
    //   }
    // },
  }
}
</script>

<style lang="scss" scoped>
.details-cont{
  min-height: 100.1vh;
  background: #F8F8F8;
  box-sizing: border-box;
  padding-bottom: 30px;
}
.details-name{
  font-size: 44px;
  line-height: 60px;
  padding: 26px;
  color: #000000;
  background-color: #fff;
}
.form-sub{
  width: 630px;
  height: 100px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 100px;
  color: #fff;
  margin: 0 auto;
  margin-top: 30px;
}
.box{
  background-color: #fff;
  padding: 28px 0;
  margin-bottom: 20px;
}
.box-name{
  color: #000000;
  height: 32px;
  font-size: 32px;
  line-height: 32px;
  padding-left: 20px;
  margin-bottom: 20px;
  border-left: 6px solid #1F955B;
}
.box-cont{
  padding: 0 20px;
}
.on{
  color: #1F955B;
  font-weight: 700;
}
table{
  width: 100%;
    border-collapse: collapse;
    font-size: 26px;
    line-height: 36px;
}
table,table tr td { 
    border:1px solid #E0E0E0; 
}
table tr td{
    padding: 22px 18px;
}
.d-th{
  background-color: #F9F9F9;
  text-align: center;
  box-sizing: border-box;
  width: 156px;
}
.d-td{
  width: 200;
}
.red{
  color: orange;
}
</style>